import { type FormInstance } from 'ant-design-vue/es/form'
import { onClickOutside } from '@vueuse/core'
import { type MaybeRef } from 'vue'

/**
 * 实现点击指定dom区域之外的元素时触发的关闭antform提示
 * @param fromValidate
 */
export default function useHideFormHelp(from: MaybeRef<FormInstance>){
  const _from = toRef(from)
  const target = ref(null)
  const checking = useBoolean();
  
  // @ts-ignore
  onClickOutside(target, event => {
    if(checking.is){
      _from.value?.clearValidate()
      checking.setFalse();
    }
  })
  
  return {
    target,
    checking,
  }
}
