import { reactiveComputed } from '@vueuse/core'
import isEmpty from 'lodash-es/isEmpty'
import type { FormInstance, FormItemProps, FormProps, RuleObject } from 'ant-design-vue/es/form'
import type { UnwrapRef } from '~/composables/vue'

export { useAntFormValidator }

function useAntFormValidator<Conf extends ConfigTemplate>(config: Conf) {
  type TFormState = GetFormState<Conf>

  const $form = ref<FormExpose>()
  const formState = reactive(Object.fromEntries(Object.entries(config).map(([k, v]) => [k, v.value]))) as TFormState
  const formProps = reactiveComputed<FormProps>(() => {
    const _config = { ...unref(config as FormProps['rules']) }

    for (const key of Object.keys(_config)) {
      const item = _config[key]

      // 删除自定义字段
      Reflect.deleteProperty(item, 'value')

      // 删除空对象规则
      if (isEmpty(item))
        Reflect.deleteProperty(_config, key)
    }

    return {
      model: formState,
      rules: _config,
      ref: (c: any) => ($form.value = c),
    }
  })
  const formItemProps = reactiveComputed(() => {
    const props = Object.fromEntries(
      Object.entries(formState).map(([k]): [string, FormItemProps] => [k, { name: k }]),
    ) as Record<keyof TFormState, FormItemProps>

    return props
  })

  return { $form, formState, formProps, formItemProps }
}

type FormExpose = Pick<
  FormInstance,
  'clearValidate' | 'getFieldsValue' | 'resetFields' | 'scrollToField' | 'validate' | 'validateFields'
>
type ConfigTemplate = Record<
  string,
  RuleObject & {
    value: Ref<any>
  }
>
type GetFormState<C extends ConfigTemplate> = {
  [K in keyof C]: UnwrapRef<C[K]['value']>;
}
